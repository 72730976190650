import { getErrorMessage } from '@/helpers/utils/getErrorMessage';
import packageInfo from '../../package.json'

export default {
  data() {
    return {
      client: process.env.VUE_APP_THEME.toLowerCase()
    }
  },

  computed: {
    appVersion() {
      return packageInfo.version;
    },
    isDevelopmEnviroment () {
      return process.env.VUE_APP_ENVIROMENT === 'develop'
    },
    appSystemFrom () {
      if (this.$route.query?.from) {
        return this.$route.query.from.toLocaleUpperCase()
      }

      return String(process.env.VUE_APP_SYSTEM_FROM_PA).toLocaleUpperCase()
    },
    appSystemTo () {
      return String(process.env.VUE_APP_SYSTEM_TO_PA).toLocaleUpperCase()
    },
    appLogo() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'

        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoChat() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'
        case 'MAXPLANET':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/maxplanet.svg?alt=media&token=e943bfd1-6e86-4438-9f62-e21d7afc3c81'

        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoWhite() {
      if (this.client === "axia") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
      }

      return process.env.VUE_APP_IMG_LOGO_BRANCA
    },
    appDeeplink() {
      if (this.$route.query.deeplink) {
        return this.$route.query.deeplink.toLocaleLowerCase()
      }

      return this.appSystemFrom !== this.appSystemTo ? process.env.VUE_APP_AXIA_DEEPLINK : process.env.VUE_APP_IOS_DEEPLINK
    },
    giftCardSvg () {
      if (this.client === "maxplanet") {
        return require('@/assets/gift_card_3.svg')
      }
      if (this.client === "medgold") {
        return require('@/assets/gift_card_medgold.svg')
      }
      if (this.client === "ucardz") {
        return require('@/assets/gift_card_ucardz.svg')
      }
      if (this.client === "maissaude") {
        return require('@/assets/gift_card_maissaude.svg')
      }
      if (this.client === "associado") {
        return require('@/assets/gift_card_associado.svg')
      }
      if (this.client === "soumais") {
        return require('@/assets/gift_card_soumais.svg')
      }

      return require('@/assets/gift_card_2.svg')
    },
    svgColor () {
      const medicarColor = '#730707'

      return `var(--mainColor, ${medicarColor})`
    },
    privacyForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/priv-maxplanet.pdf?alt=media&token=cc81fa1b-4802-4af7-9fb4-d90d51e4ef9b#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_MEDGOLD_23_07_24.pdf?alt=media&token=19028df6-4039-4ee9-a413-72d6d9d0fe42'
      }

      if (this.client === 'ucardz') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_uCarz_04092024.pdf?alt=media&token=055216c6-adac-42c1-be14-54f2765141fa'
      }

      if (this.client === 'acessa') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_acessa.pdf?alt=media&token=76c8e394-5f06-4453-9575-73353f846998'
      }

      if (this.client === 'maissaude') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_maissaude.pdf?alt=media&token=85c114a4-5349-4d5d-93d7-f5e1a61bd65b'
      }

      if (this.client === 'associado') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/politica_de_privacidade_associados.pdf?alt=media&token=111b6932-7cb4-486a-918c-4e89266e5835'
      }

      if (this.client === 'soumais') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_Sou_Mais_Brasil.pdf?alt=media&token=082f3f9f-361b-4d22-a434-81d6748ff462'
      }

      return null
    },
    consentForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/terms-maxplanet.pdf?alt=media&token=f4d92cac-9af5-40fe-95b4-5a98ff1115c6#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_MEDGOLD_23_07_24.pdf?alt=media&token=47667713-426a-47ff-b5bd-8968ebab5e0d'
      }

      if (this.client === 'ucardz') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_uCardz_04092024.pdf?alt=media&token=059d3e2c-d5cd-4b2c-b488-fd68bfd63f83'
      }

      if (this.client === 'acessa') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_acessa.pdf?alt=media&token=7082f1e4-3a7e-469c-a8ba-afbe97bfb45e'
      }

      if (this.client === 'maissaude') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_maissaude.pdf?alt=media&token=af0f9e7e-7f5b-4271-9703-734f8d79d26d'
      }

      if (this.client === 'associado') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/termos_de_uso_associados.pdf?alt=media&token=db62e67b-3341-4969-848e-fe8843a518b0'
      }

      if (this.client === 'soumais') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_Sou_Mais_Brasil.pdf?alt=media&token=18db1912-5f77-4588-9814-a21c9b035eac'
      }

      return null
    },
    termComponentName() { // return the component name
      if(["maxplanet", "medgold", "maissaude", "acessa", "ucardz",'associado', 'soumais'].includes(this.client)) {
        return "ConsentTermIframe"
      } else if (this.client === "axia") {
        return "TermAxia"
      } else {
        return "TermMedicar";
      }
    },
  },
  methods: {
    getErrorMessage,
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(value) {
        if (value?.from) {
          document.getElementById("root").className = this.appSystemFrom.toLocaleLowerCase()

          if (value.from.toUpperCase() === 'AXIA') {
            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", `${process.env.VUE_APP_AXIA_PACIENTE}/favicon.png`);
          }
        }
      }
    }
  }
};
